import React, { useState, useEffect } from "react";
import "./menuadmin.css";

const MenuAdmin = ({ globalData }) => {
  const [menuItems, setMenuItems] = useState({ title: "", submenu: [] });

  useEffect(() => {
    if (
      globalData &&
      globalData.menuItemsData &&
      globalData.menuItemsData.length > 0
    ) {
      // Устанавливаем первый элемент массива menuItemsData
      setMenuItems(globalData.menuItemsData[0]);
    }
  }, [globalData]); // Добавляем globalData в зависимости

  const handleEditSubmenuItem = async (index) => {
    const newTitle = prompt("Enter new title for the submenu item:");
    if (newTitle) {
      const updatedMenuItems = [...menuItems.submenu];
      updatedMenuItems[index] = {
        ...updatedMenuItems[index],
        title: newTitle,
      };
      const updatedMenu = { ...menuItems, submenu: updatedMenuItems };
      setMenuItems((prevMenuItems) => ({
        ...prevMenuItems,
        submenu: updatedMenuItems,
      }));
      await updateMenu(updatedMenu); // Передаем обновленное меню
    }
  };

  // Функция для отправки POST-запроса на сервер для обновления меню
  const updateMenu = async (updatedMenu) => {
    console.log("Updated Menu Data:", updatedMenu);
    try {
      const response = await fetch("/api/updateMenu", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedMenu),
      });
      const responseData = await response.json();
      console.log(responseData.message); // Выводим сообщение об успешном обновлении меню
    } catch (error) {
      console.error("Error updating menu:", error);
    }
  };

  return (
    <div className="admin_menu">
      <h2>{menuItems.title}</h2>
      <ul>
        {menuItems.submenu &&
          menuItems.submenu.map((item, submenuIndex) => (
            <li key={item.id}>
              {item.title}
              <button onClick={() => handleEditSubmenuItem(submenuIndex)}>
                Edit
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MenuAdmin;
