
import DisplayTrack from "./DropRadio/displayTrack";
import Controls from "./DropRadio/controls";
import {  useRef } from "react";

const Audioplayer = ({ currentTrack, onClick }) => {
  const audioRef = useRef();

  return (
    <>
      <div className="audio-pl" onClick={onClick}>
        <Controls audioRef={audioRef} />
        <div className="inner">
          <DisplayTrack currentTrack={currentTrack} audioRef={audioRef} />
        </div>
      </div>
    </>
  );
};

export default Audioplayer;