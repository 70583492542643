const FooterAdmin = () => {
  return (
    <div>
      <h1>Admin Footer</h1>
      {/* Добавьте код для административного подвала здесь */}
    </div>
  );
};

export default FooterAdmin;
