import Dropdown from "../Dropdown/dropdown";
import Dropradio from "../DropRadio/dropradio";
import { Link as LinkS } from "react-scroll";
import Audioplayer from "../audioplayer";
import Equalizer from "../Equalizer/equalizer";
import LogoTable from "../Logosnav/logotable";
import { LogoTicket } from "../Logosnav/logoticket";
import { tracks } from "src/assets/player/tracks";
import { useState, useEffect, useRef } from "react";

const MenuItems = ({ items }) => {
  const [dropdown, setDropdown] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(tracks[0]);

  const closeDropdown = () => {
    setTimeout(() => {
      dropdown && setDropdown(false);
    }, 700);
  };

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);
  const handleChildElementClick = (e) => {
    e.stopPropagation();
    // Do other stuff here
  };

  return (
    <div
      className={items.id}
      ref={ref}
      onClick={() => setDropdown((prev) => !prev)}
    >
      {items.submenu ? (
        <>
          <div
            type="button"
            className="buton"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
          >
            {items.title}{" "}
          </div>
          {items.id === "radio" ? (
            <>
              {" "}
              <div className="barplayerwrapper">
                <Audioplayer
                  currentTrack={currentTrack}
                  onClick={(e) => handleChildElementClick(e)}
                />
              </div>
              <Equalizer
                onClick={() => setDropdown((prev) => !prev)}
                dropdown={dropdown}
              />
              <Dropradio
                dropdown={dropdown}
                setCurrentTrack={setCurrentTrack}
                currentTrack={currentTrack}
                onClick={(e) => handleChildElementClick(e)}
              />
            </>
          ) : (
            <>
              <LinkS to="about" offset={-105} spy={true} smooth={true}>
                <LogoTable
                  onClick={() => setDropdown((prev) => !prev)}
                  items={items}
                />
              </LinkS>

              <Dropdown
                submenus={items.submenu}
                dropdown={dropdown}
                onClick={closeDropdown}
              />
            </>
          )}
        </>
      ) : (
        <>
          <LogoTicket />

          <LinkS to="tickets" spy={true} smooth={true}>
            <div className="ticket_ticket">{items.title}</div>
          </LinkS>
        </>
      )}
    </div>
  );
};

export default MenuItems;
