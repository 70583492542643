import table1 from "src/assets/images/table.png"
import table2back from "src/assets/images/table2.png"

import "./logotable.css"

const LogoTable = ({onClick}) => {
    return (
      <div className="logotable" onClick={onClick}>
        
          <img className="table1" src={table1} alt=""></img>
          <img className="table2" src={table2back} alt=""></img>
       
      </div>
    );
}

export default LogoTable