import About from "./About/about";
import Gallery from "./Gallery/gallery";
import Tickets from "./Tickets/tickets";
import { useTranslation } from "react-i18next";

//import { menuItemsData } from "../menuItemsData";

import "./content.css";




const Content = () => {
  const { t } = useTranslation("global");
  const aboutTitle = t('menuItemsData.0.submenu.0.title');
  const galleryTitle = t('menuItemsData.0.submenu.1.title');
  const ticketsTitle = t('menuItemsData.0.submenu.2.title');
  return (
    <>
      <div className="content">
        <About aboutTitle={aboutTitle} />
        <Gallery galleryTitle={galleryTitle} />
        <Tickets ticketsTitle={ticketsTitle} />
        
      </div>
    </>
  );
};

export default Content;

