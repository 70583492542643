
const ListOfTracks = ({ items, onClick }) => {
  return (
    <>
    
      <div onClick={onClick}>{items.title}</div>
    </>
  );
};

export default ListOfTracks;