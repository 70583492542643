import "./Wsub-gallery.css";
import Slider from "react-touch-drag-slider";
import { useState } from "react";

const WsubGallery = ({ images }) => {
  const [index, setIndex] = useState(1);
  const setFinishedIndex = (i) => {
    console.log("finished dragging on slide", i);
    setIndex(i);
  };
  const next = () => {
    if (index < images.length - 1) setIndex(index + 1);
  };

  const previous = () => {
    if (index > 0) setIndex(index - 1);
  };
  return (
    <>
      <div
        className="buttonPrev"
        onClick={previous}
        left="true"
        disabled={index === 0}
      >
        〈
      </div>
      <div
        className="buttonNext"
        onClick={next}
        right="true"
        disabled={index === images.length - 1}
      >
        〉
      </div>
      <div className="subSlide">
        <Slider
          onSlideComplete={setFinishedIndex}
          onSlideStart={(i) => {
            console.clear();
            console.log("started dragging on slide", i);
          }}
          activeIndex={index}
          threshHold={100}
          transition={0.2}
          scaleOnDrag={true}
        >
          {images.map(({ img, title }, idx) => (
            <img src={img} key={idx} alt={title} />
          ))}
        </Slider>
      </div>
    </>
  );
};

export default WsubGallery;
