import { useTranslation } from "react-i18next";
import vavborder from "src/assets/images/border2.svg";
import boxtitle from "src/assets/images/box.svg";
import psycampimg from "src/assets/images/psycamp1.jpg";
import "./about.css";

const About = ({ aboutTitle }) => {
  const { t } = useTranslation("global");

  

 
  
  return (
    <div
      className="about"
     
    >
      <div className="verhramka" >
        <img className="just_ramka" src={vavborder} alt=""></img>
        <div className="ramkatitle">
          <h2>{aboutTitle}</h2>
          <img src={boxtitle} alt="" />
        </div>
      </div>

      <div className="about_content" >
        <div className="text_wrapper" >
          <blockquote>{t("about.parfirst")}</blockquote>
          <div className="text_image">
            <img src={psycampimg} alt="" />
          </div>{" "}
          <blockquote>{t("about.parsecond")}</blockquote>
          <blockquote>{t("about.parthird")}</blockquote>
          <blockquote>{t("about.parfour")}</blockquote>
        </div>
      </div>
      <div className="nizramka">
        <img src={vavborder} alt=""></img>
      </div>
    </div>
  );
};

export default About;
