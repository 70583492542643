import WSPGallery from "./WSPGallery";
import { galleryImages } from "./galleryImages";
import vavborder from "src/assets/images/border2.svg";
import boxtitle from "src/assets/images/box.svg";
import "./gallery.css";

const Gallery = ({ galleryTitle }) => {
 
  return (
    <div className="gallerywrap" >
      <div className="verhramka">
        <div className="ramka_img_wrap"></div>
        <img src={vavborder} alt=""></img>
        <div className="ramkatitle">
          <h2>{galleryTitle}</h2>
          <img src={boxtitle} alt="" />
        </div>
      </div>
      <div className="gallery">
        <WSPGallery galleryImages={galleryImages} />
      </div>
      <div className="nizramka">
        <img src={vavborder} alt=""></img>
      </div>
    </div>
  );
};

export default Gallery;
