import React, { useEffect } from "react";
import "./index.css";
import AdminPanel from "./components/AdminPanel/admin";
import Hero from "./components/Hero/hero";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import global_en from "./locales/en/global.json";
import global_uk from "./locales/uk/global.json";

const getLanguageFromAcceptLanguage = (acceptLanguage) => {
  if (!acceptLanguage) return "en";

  const languages = acceptLanguage.split(",");
  for (const language of languages) {
    const [languageCode] = language.split(";")[0].trim().split("-");
    if (languageCode === "ru" || languageCode === "uk") return "uk";
  }

  return "en";
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: "en",
    resources: {
      en: {
        global: global_en,
      },
      uk: {
        global: global_uk,
      },
    },
  });

const MainApp = () => {
  useEffect(() => {
    const acceptLanguage = navigator.language;
    const language = getLanguageFromAcceptLanguage(acceptLanguage);
    i18next.changeLanguage(language);
  }, []);

  // Проверяем текущий путь URL и рендерим компоненты в зависимости от пути
  if (window.location.pathname === "/admin") {
    return (
      <I18nextProvider i18n={i18next}>
        <AdminPanel />
      </I18nextProvider>
    );
  } else {
    return (
      <I18nextProvider i18n={i18next}>
        <Hero />
      </I18nextProvider>
    );
  }
};

export default MainApp;
