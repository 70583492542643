const HeaderAdmin = () => {
  return (
    <div>
      <h1>Admin Header</h1>
      {/* Добавьте код для административного заголовка здесь */}
    </div>
  );
};

export default HeaderAdmin;
