import MenuItems from "./Menuitems/menuItems";
//import { menuItemsData } from "../menuItemsData";
import { useTranslation } from "react-i18next";
import vavborder from "src/assets/images/border2.svg"
import "./navbar.css";
import { init } from "i18next";

const Navbar = () => {

  const { t, ready } = useTranslation("global");
  if (!ready) return "loading translations...";
  const menuItemsData = t("menuItemsData", { returnObjects: init });

  
  //const { t, i18n, ready } = useTranslation();
  //if (!ready) return "loading translations...";
  //const menuItemsData = t('menuItemsData', { returnObjects: true });

  return (
    <div className="desktop-nav">
      <div className="navwrap">
        <div className="logoNazva">
          PsyCamp <br /> 2024
        </div>
        <div className="logoDate">21-23 June</div>
        <div className="menus">
          {menuItemsData.map((menu, index) => {
            return <MenuItems items={menu} key={index} />;
          })}
        </div>
        <div className="navborder">
          <img src={vavborder} alt=""></img>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
