import {
  faYoutube,
  faFacebook,
  faTelegram,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const SocialMedia = () => {
  const { t } = useTranslation("global");
  return (
    <div className="socialwraper">
      <h4>{t("footer.followus")}</h4>
      <div className="social-container">
        <a
          href="https://www.facebook.com/tranceconnections2020/"
          className="facebook social"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://www.instagram.com/tranceconnections/"
          className="instagram social"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://t.me/tranceconnections"
          className="telegram social"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTelegram} />
        </a>
        <a
          href="https://www.youtube.com/@tranceconnectionsfestivalo3285"
          className="youtube social"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
