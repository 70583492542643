import SocialMedia from "./socialmedia";
import vavborder from "src/assets/images/border2.svg";
import { useTranslation } from "react-i18next";



import "./footer.css";

const Footer = () => {
  const { t, i18n } = useTranslation("global");
  
  
  return (
    <div className="footer_wraper">
      <div className="footer_border" >
        <img src={vavborder} alt=""></img>
      </div>
      <div className="footer">
        <SocialMedia />
        <div className="lang_chooser">
          <h4 className="lang_text">{t("footer.chooseLanguage")}</h4>
          <div className="lang_button">
            <div
              className="flag_gb"
              onClick={() => i18n.changeLanguage("en")}
             
              
            >
              <img
                alt="GB"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg"
              />
            </div>
            <div
              className="flag_gb"
              onClick={() => i18n.changeLanguage("uk")}
              
              
            >
              <img
                alt="UK"
                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/UA.svg"
              />
            </div>
          </div>
        </div>
        <p>@Tranceconnections</p>
      </div>
    </div>
  );
};

export default Footer;
