import liora from "src/assets/sounds/Liora@kazka2021.mp3";
import gydravlik from "src/assets/sounds/GYDRAVLIK_PSYCAMP.mp3";
import anyname23 from "src/assets/sounds/Anyname@Psycamp23.mp3";
import dj369 from "src/assets/sounds/Dj369@Solstice2024.mp3";

export const tracks = [
  {
    title: "Gydravlik@psycamp23",
    src: gydravlik,
  },
  { title: "Dj_369@solstice2024",
    src: dj369,
  },
  {
    title: "Liora@kazka2021",
    src: liora,
  },

  {
    title: "Anyname@Psycamp23",
    src: anyname23,
  },
];
