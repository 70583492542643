import ticket from "src/assets/images/tickets.svg"
import { Link as LinkS } from "react-scroll";
import "./logoticket.css"

export const LogoTicket = () => {
    return (
      <LinkS to="tickets" spy={true} smooth={true}>
        <div className="logoticketwrap">
          <img src={ticket} alt="" />
        </div>
      </LinkS>
    );
}

