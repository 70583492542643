import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
  
} from "react-router-dom";
import MenuAdmin from "./MenuAdm/menuadmin";
import HeaderAdmin from "./HeaderAdm/headeradmin";
import FooterAdmin from "./FooterAdm/footeradmin";
import Login from "./Login"; // Импортируем компонент Login
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_en from "src/locales/en/global";
import global_uk from "src/locales/uk/global";
import "./admin.css";

const AdminPanel = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Добавляем состояние для отслеживания аутентификации

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    i18next.changeLanguage(language);
  };

  const handleLogin = ({ username, password }) => {
    // Здесь должна быть ваша логика проверки учетных данных
    // Например, вы можете сравнивать их с предопределенными значениями или отправлять на сервер для проверки

    // В этом примере мы просто сравниваем с жестко заданными значениями
    if (username === "admin" && password === "password") {
      setIsLoggedIn(true);
    } else {
      alert("Invalid username or password.");
    }
  };
  const handleLogout = () => {
    setIsLoggedIn(false); // Устанавливаем состояние isLoggedIn в false, чтобы вернуться на страницу входа
  };

  return (
    <I18nextProvider i18n={i18next}>
      <Router>
        <div className="admin_wrapper">
          {!isLoggedIn ? (
            <Login onLogin={handleLogin} /> // Отображаем компонент Login, если пользователь не аутентифицирован
          ) : (
            <>
              <div style={{ flex: "1", borderRight: "1px solid black" }}>
                <button onClick={() => changeLanguage("en")}>EN</button>
                <button onClick={() => changeLanguage("uk")}>UK</button>
                <button onClick={handleLogout}>Logout</button>{" "}
                {/* Кнопка Logout */}
                <ul>
                  <li>
                    <Link to="/admin/menu">Menu</Link>
                  </li>
                  <li>
                    <Link to="/admin/header">Header</Link>
                  </li>
                  <li>
                    <Link to="/admin/footer">Footer</Link>
                  </li>
                </ul>
              </div>
              <div style={{ flex: "3", paddingLeft: "20px" }}>
                <Routes>
                  <Route
                    path="/admin/menu"
                    element={
                      <MenuAdmin
                        globalData={
                          selectedLanguage === "en" ? global_en : global_uk
                        }
                      />
                    }
                  />
                  <Route path="/admin/header" element={<HeaderAdmin />} />
                  <Route path="/admin/footer" element={<FooterAdmin />} />
                </Routes>
              </div>
            </>
          )}
        </div>
      </Router>
    </I18nextProvider>
  );
};

export default AdminPanel;
