import  { useState } from "react";

import { useSpring, animated } from "react-spring";



const TextScroller = ({ text }) => {
  const [key, setKey] = useState(1);

  const scrolling = useSpring({
    from: { transform: "translate(10%,0)" },
    to: { transform: "translate(-65%,0)" },
    config: { duration: 7000 },
    reset: true,
    //reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <div className="textroller">
      <div className="animated" key={key}>
        <animated.div style={scrolling}>{text}</animated.div>
      </div>
    </div>
  );
};

export default TextScroller;
