import TextScroller from "./textscroller";
const DisplayTrack = ({ currentTrack, audioRef }) => {
  return (
    <div>
      <audio src={currentTrack.src} ref={audioRef} type="audio/mpeg" />
      <div>
        <TextScroller text={currentTrack.title} />
      </div>
    </div>
  );
};
export default DisplayTrack;
