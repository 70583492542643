
import "./forms.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import TermsOfService from "./terms"; // Importing termsOfService from terms.js

export default function Formas() {
  const { t } = useTranslation("global");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [quantity, setQuantity] = useState(1);
  const [ticketPrice, setTicketPrice] = useState(30);
  const [total, setTotal] = useState(ticketPrice * quantity);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    setTotal(ticketPrice * quantity);
  }, [quantity, ticketPrice]);

  const handleIncrease = () => {
    if (quantity < 5) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  const handleCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <div>
      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Name Surname*"
            name="namesurname"
            {...register("namesurname", {
              required: "Name and Surname are required",
            })}
          />
          {errors.namesurname && <p>{errors.namesurname.message}</p>}

          <input
            type="text"
            placeholder="Email*"
            name="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid email address",
              },
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}

          <label htmlFor="ticket">{t("forms.ChooseYourTicket")}</label>
          <select
            id="ticket"
            name="ticket"
            {...register("ticket", { required: "Please choose a ticket" })}
            onChange={(e) =>
              setTicketPrice(e.target.value === "full" ? 30 : 17)
            }
          >
            <option value="full">Full 2 days ticket - $30</option>
            <option value="oneday">One day (Saturday only) ticket - $17</option>
          </select>
          {errors.ticket && <p>{errors.ticket.message}</p>}

          <label htmlFor="quantity">{t("forms.ChooseQuantity")}</label>
          <div>
            <button type="button" onClick={handleDecrease}>
              -
            </button>
            <input
              type="text"
              id="quantity"
              name="quantity"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
              readOnly
              className="quantity-input"
            />
            <button type="button" onClick={handleIncrease}>
              +
            </button>
          </div>

          <label>Total: ${total}</label>

          <label>
            <input
              name="acceptedTerms"
              type="checkbox"
              checked={termsAccepted}
              onChange={handleCheckboxChange}
            />
            {t("forms.IAccept")}
          </label>
          <button
            type="button"
            onClick={toggleTermsModal}
            disabled={termsAccepted}
          >
            {t("forms.ReadTerms")}
          </button>

          <button type="submit" disabled={!termsAccepted}>
            {t("forms.Submit")}
          </button>
        </form>
      </div>
      <div
        className={`modal ${showTermsModal ? "show" : ""}`}
        onClick={toggleTermsModal}
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <span className="close" onClick={toggleTermsModal}>
            &times;
          </span>
          <TermsOfService />
        </div>
      </div>
    </div>
  );
}