
import Footer from "../Footer/footer";
import Content from "../Content/content";
import Navbar from "../Navbar/navbar";



import "./hero.css";

const Hero = () => {
   const handleContextMenu = (event) => {
     event.preventDefault();
   };
  return (
    <>
      <div className="fixed-background"></div>

      <div className="main-page" onContextMenu={handleContextMenu}>
        <Navbar />
        <Content />
        <Footer />
      </div>
    </>
  );
};

export default Hero;