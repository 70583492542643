import PlayButton from "./play";
import PouseButton from "./pouse";
import { useState, useEffect } from "react";

const Controls = ({ audioRef }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };
   useEffect(() => {
     if (isPlaying) {
       audioRef.current.play();
     } else {
       audioRef.current.pause();
     }
   }, [isPlaying, audioRef]);
  return (
    <div className="controls-wrapper">
      <div className="controls" onClick={togglePlayPause}>
        {isPlaying ? <PouseButton /> : <PlayButton />}
      </div>
    </div>
  );
};

export default Controls;