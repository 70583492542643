import "./equalizer.css";


const Equalizer = () => {
  return (
    <div className="eqcontainer">
      
      <div className="box">
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
        <div className="line4"></div>
        <div className="line5"></div>
      </div>
    </div>
  );
};

export default Equalizer;
