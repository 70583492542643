import kazka2020 from "src/assets/gallery/2020/4.jpg";
import img20_1 from "src/assets/gallery/2020/1.jpg";
import img20_2 from "src/assets/gallery/2020/10.jpg";
import img20_3 from "src/assets/gallery/2020/11.jpg";
import img20_4 from "src/assets/gallery/2020/117933311_2041995582602878_8699856432411093790_o.jpg";
import img20_5 from "src/assets/gallery/2020/117949933_1666099366877868_5330060959611532327_o.jpg";
import img20_6 from "src/assets/gallery/2020/117962660_3345171002264735_1185186278342538914_o.jpg";
import img20_7 from "src/assets/gallery/2020/117968126_2041992099269893_4165442374906427_o.jpg";
import img20_8 from "src/assets/gallery/2020/117968950_2041994485936321_5569427854226079961_o.jpg";
import img20_9 from "src/assets/gallery/2020/117973627_2041990659270037_8142807749305225876_o.jpg";
import img20_10 from "src/assets/gallery/2020/117982705_1666101990210939_4828354275305262956_o.jpg";
import img20_11 from "src/assets/gallery/2020/117985967_1666100126877792_838110367062811371_o.jpg";
import img20_12 from "src/assets/gallery/2020/117986433_1666097836878021_1778611057599701906_o.jpg";
import img20_13 from "src/assets/gallery/2020/117990697_2041993692603067_7865277934092369651_o.jpg";
import img20_14 from "src/assets/gallery/2020/117995050_4115987855138621_4887358426848489225_o.jpg";
import img20_15 from "src/assets/gallery/2020/117999804_1666102326877572_3385224310666789466_o.jpg";
import img20_16 from "src/assets/gallery/2020/118001890_4115986731805400_5992993913570260398_o.jpg";
import img20_17 from "src/assets/gallery/2020/118012516_1666101810210957_4331369153628156708_o.jpg";
import img20_18 from "src/assets/gallery/2020/118032815_2041992122603224_3819074829858039868_o.jpg";
import img20_19 from "src/assets/gallery/2020/118016519_1666096473544824_6881060532986357388_o.jpg";
import img20_20 from "src/assets/gallery/2020/118064953_1666096520211486_6732337977657424758_o.jpg";







import kazka2021 from "src/assets/gallery/2021/1.jpg";
import img21_1 from "src/assets/gallery/2021/1.jpg";
import img21_2 from "src/assets/gallery/2021/2.jpg";
import img21_3 from "src/assets/gallery/2021/photo_2021-08-16_12-28-02.jpg";
import img21_4 from "src/assets/gallery/2021/photo_2021-08-16_12-28-03.jpg"
import img21_5 from "src/assets/gallery/2021/photo_2021-08-16_12-28-06.jpg"
import img21_6 from "src/assets/gallery/2021/photo_2021-08-16_15-22-25.jpg";
import img21_7 from "src/assets/gallery/2021/photo_2021-08-16_15-22-27.jpg";
import img21_8 from "src/assets/gallery/2021/photo_2021-08-16_15-22-28.jpg";
import img21_9 from "src/assets/gallery/2021/photo_2021-08-17_15-07-13.jpg";
import img21_10 from "src/assets/gallery/2021/photo_2021-08-17_18-46-53.jpg";
import img21_11 from "src/assets/gallery/2021/photo_2021-08-18_14-00-09.jpg";
import img21_12 from "src/assets/gallery/2021/photo_2021-08-18_14-00-33.jpg";
import img21_13 from "src/assets/gallery/2021/photo_2021-08-18_14-00-34.jpg";
import img21_14 from "src/assets/gallery/2021/photo_2021-08-18_14-00-35.jpg";
import img21_15 from "src/assets/gallery/2021/photo_2021-08-18_14-00-41.jpg";
import img21_16 from "src/assets/gallery/2021/photo_2021-08-18_14-00-44.jpg";



import psycamp2023 from "src/assets/gallery/2023/img1.jpg";
import img23_1 from "src/assets/gallery/2023/img1.jpg";
import img23_2 from "src/assets/gallery/2023/img10.jpg";
import img23_3 from "src/assets/gallery/2023/img11.jpg";
import img23_4 from "src/assets/gallery/2023/img12.jpg";
import img23_5 from "src/assets/gallery/2023/img13.jpg"
import img23_6 from "src/assets/gallery/2023/img14.jpg"
import img23_7 from "src/assets/gallery/2023/img2.jpg";
import img23_8 from "src/assets/gallery/2023/img3.jpg";
import img23_9 from "src/assets/gallery/2023/img4.jpg";
import img23_10 from "src/assets/gallery/2023/img5.jpg";
import img23_11 from "src/assets/gallery/2023/img6.jpg";
import img23_12 from "src/assets/gallery/2023/img7.jpg";
import img23_13 from "src/assets/gallery/2023/img8.jpg";
import img23_14 from "src/assets/gallery/2023/img9.jpg";



import solstice2024 from "src/assets/gallery/2024/4.9.jpg";
import img24_1 from "src/assets/gallery/2024/4.1.jpg"
import img24_2 from "src/assets/gallery/2024/4.2.jpg";
import img24_3 from "src/assets/gallery/2024/4.3.jpg";
import img24_4 from "src/assets/gallery/2024/4.4.jpg";
import img24_5 from "src/assets/gallery/2024/4.5.jpg";
import img24_6 from "src/assets/gallery/2024/4.6.jpg";
import img24_7 from "src/assets/gallery/2024/4.7.jpg";
import img24_8 from "src/assets/gallery/2024/4.8.jpg";
import img24_9 from "src/assets/gallery/2024/4.9.jpg";
import img24_10 from "src/assets/gallery/2024/4.10.jpg";
import img24_11 from "src/assets/gallery/2024/4.11.jpg";
import img24_12 from "src/assets/gallery/2024/4.12.jpg";
import img24_13 from "src/assets/gallery/2024/4.13.jpg";
import img24_14 from "src/assets/gallery/2024/4.14.jpg";
import img24_15 from "src/assets/gallery/2024/4.15.png";
import img24_16 from "src/assets/gallery/2024/4.16.jpg";
import img24_17 from "src/assets/gallery/2024/4.17.jpg";
import img24_18 from "src/assets/gallery/2024/4.18.jpg";
import img24_19 from "src/assets/gallery/2024/4.19.jpg";
import img24_20 from "src/assets/gallery/2024/4.20.jpg";
import img24_21 from "src/assets/gallery/2024/4.21.jpg";
import img24_22 from "src/assets/gallery/2024/4.22.jpg";
import img24_23 from "src/assets/gallery/2024/4.23.jpg";
import img24_24 from "src/assets/gallery/2024/4.24.jpg";
import img24_25 from "src/assets/gallery/2024/4.25.jpg";
import img24_26 from "src/assets/gallery/2024/4.26.jpg";
import img24_27 from "src/assets/gallery/2024/4.27.jpg";
import img24_28 from "src/assets/gallery/2024/4.28.jpg";
import img24_29 from "src/assets/gallery/2024/4.29.jpg";
import img24_30 from "src/assets/gallery/2024/4.30.jpg";
import img24_31 from "src/assets/gallery/2024/4.31.jpg";
import img24_32 from "src/assets/gallery/2024/4.32.jpg";
import img24_33 from "src/assets/gallery/2024/4.33.jpg";
import img24_34 from "src/assets/gallery/2024/4.34.jpg";
import img24_35 from "src/assets/gallery/2024/4.35.jpg";
import img24_36 from "src/assets/gallery/2024/4.36.jpg";
import img24_37 from "src/assets/gallery/2024/4.37.jpg";
import img24_38 from "src/assets/gallery/2024/4.38.jpg";
import img24_39 from "src/assets/gallery/2024/4.39.jpg";
import img24_40 from "src/assets/gallery/2024/4.40.jpg";
import img24_41 from "src/assets/gallery/2024/4.41.jpg";

import img24_43 from "src/assets/gallery/2024/4.43.jpg";
import img24_44 from "src/assets/gallery/2024/4.44.jpg";
import img24_45 from "src/assets/gallery/2024/4.45.png";
import img24_46 from "src/assets/gallery/2024/4.46.png";
import img24_47 from "src/assets/gallery/2024/4.47.png";
import img24_48 from "src/assets/gallery/2024/4.48.png";
import img24_49 from "src/assets/gallery/2024/4.49.png";
import img24_50 from "src/assets/gallery/2024/4.50.png";
import img24_51 from "src/assets/gallery/2024/4.51.png";
import img24_52 from "src/assets/gallery/2024/4.52.png";


export const galleryImages = [
  {
    img: kazka2020,
    id: "kazka2020",
    title: "KAZKA2020",
    subgallery: [
      { img: img20_1, id: "img20_1" },
      { img: img20_2, id: "img20_2" },
      { img: img20_3, id: "img20_3" },
      { img: img20_4, id: "img20_4" },
      { img: img20_5, id: "img20_5" },
      { img: img20_6, id: "img20_6" },
      { img: img20_7, id: "img20_7" },
      { img: img20_8, id: "img20_8" },
      { img: img20_9, id: "img20_9" },
      { img: img20_10, id: "img20_10" },
      { img: img20_11, id: "img20_11" },
      { img: img20_12, id: "img20_12" },
      { img: img20_13, id: "img20_13" },
      { img: img20_14, id: "img20_14" },
      { img: img20_15, id: "img20_15" },
      { img: img20_16, id: "img20_16" },
      { img: img20_17, id: "img20_17" },
      { img: img20_18, id: "img20_18" },
      { img: img20_19, id: "img20_19" },
      { img: img20_20, id: "img20_20" },
    ],
  },
  {
    img: kazka2021,
    id: "kazka2021",
    title: "KAZKA2021",
    subgallery: [
      { img: img21_2, id: "img21_2" },
      { img: img21_1, id: "img21_1" },
      { img: img21_3, id: "img21_3" },
      { img: img21_4, id: "img21_4" },
      { img: img21_5, id: "img21_5" },
      { img: img21_6, id: "img21_6" },
      { img: img21_7, id: "img21_7" },
      { img: img21_8, id: "img21_8" },
      { img: img21_9, id: "img21_9" },
      { img: img21_10, id: "img21_10" },
      { img: img21_11, id: "img21_11" },
      { img: img21_12, id: "img21_12" },
      { img: img21_13, id: "img21_13" },
      { img: img21_14, id: "img21_14" },
      { img: img21_15, id: "img21_15" },
      { img: img21_16, id: "img21_16" },
    ],
  },
  {
    img: psycamp2023,
    title: "PSYCAMP2023",
    subgallery: [
      { img: img23_2, id: "img23_2" },
      { img: img23_1, id: "img23_1" },
      { img: img23_3, id: "img23_3" },
      { img: img23_4, id: "img23_4" },
      { img: img23_5, id: "img23_5" },
      { img: img23_6, id: "img23_6" },
      { img: img23_7, id: "img23_7" },
      { img: img23_8, id: "img23_8" },
      { img: img23_9, id: "img23_9" },
      { img: img23_10, id: "img23_10" },
      { img: img23_11, id: "img23_11" },
      { img: img23_12, id: "img23_12" },
      { img: img23_13, id: "img23_13" },
      { img: img23_14, id: "img23_14" },
    ],
  },
  {
    img: solstice2024,
    title: "SOLSTICE2024",
    subgallery: [
      { img: img24_2, id: "img24_2" },
      { img: img24_1, id: "img24_1" },
      { img: img24_3, id: "img24_3" },
      { img: img24_4, id: "img24_4" },
      { img: img24_5, id: "img24_5" },
      { img: img24_6, id: "img24_6" },
      { img: img24_7, id: "img24_7" },
      { img: img24_8, id: "img24_8" },
      { img: img24_9, id: "img24_9" },
      { img: img24_10, id: "img24_10" },
      { img: img24_11, id: "img24_11" },
      { img: img24_12, id: "img24_12" },
      { img: img24_13, id: "img24_13" },
      { img: img24_14, id: "img24_14" },
      { img: img24_15, id: "img24_15" },
      { img: img24_16, id: "img24_16" },
      { img: img24_17, id: "img24_17" },
      { img: img24_18, id: "img24_18" },
      { img: img24_19, id: "img24_19" },
      { img: img24_20, id: "img24_20" },
      { img: img24_21, id: "img24_21" },
      { img: img24_22, id: "img24_22" },
      { img: img24_23, id: "img24_23" },
      { img: img24_24, id: "img24_24" },
      { img: img24_25, id: "img24_25" },
      { img: img24_26, id: "img24_26" },
      { img: img24_27, id: "img24_27" },
      { img: img24_28, id: "img24_28" },
      { img: img24_29, id: "img24_29" },
      { img: img24_30, id: "img24_30" },
      { img: img24_31, id: "img24_31" },
      { img: img24_32, id: "img24_32" },
      { img: img24_33, id: "img24_33" },
      { img: img24_34, id: "img24_34" },
      { img: img24_35, id: "img24_35" },
      { img: img24_36, id: "img24_36" },
      { img: img24_37, id: "img24_37" },
      { img: img24_38, id: "img24_38" },
      { img: img24_39, id: "img24_39" },
      { img: img24_40, id: "img24_40" },
      { img: img24_41, id: "img24_41" },
      
      { img: img24_43, id: "img24_43" },
      { img: img24_44, id: "img24_44" },
      { img: img24_45, id: "img24_45" },
      { img: img24_46, id: "img24_46" },
      { img: img24_47, id: "img24_47" },
      { img: img24_48, id: "img24_48" },
      { img: img24_49, id: "img24_49" },
      { img: img24_50, id: "img24_50" },
      { img: img24_51, id: "img24_51" },
      { img: img24_52, id: "img24_52" },
    ],
  },
];
