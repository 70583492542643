import { Link as LinkS } from "react-scroll";
import vavborder from "src/assets/images/border2.svg";

const Dropdown = ({ submenus, dropdown, onClick }) => {
  return (
    <div className={`dropdown ${dropdown ? "show" : ""}`}>
      <div className="dropdownwrap">
        {submenus.map((submenu, index) => (
          <div key={index} className="menu-items">
            <div>
              <LinkS
                to={submenu.id}
                offset={-105}
                spy={true}
                smooth={true}
                onClick={onClick}
              >
                {submenu.title}
              </LinkS>
            </div>
          </div>
        ))}
      </div>
      <div className="dropborder">
        <img src={vavborder} alt=""></img>
      </div>
    </div>
  );
};

export default Dropdown;
