import ListOfTracks from "./listOfTracks";
import { tracks } from "src/assets/player/tracks";
import Audioplayer from "../audioplayer";
import vavborder from "src/assets/images/border2.svg";

const Dropradio = ({ dropdown, setCurrentTrack, currentTrack, onClick }) => {
  return (
    <>
      <div className={`dropradio ${dropdown ? "show" : ""}`} onClick={onClick}>
        <div className="dropmenuplayer">
          <Audioplayer currentTrack={currentTrack} />
        </div>
        <div className="droptrakswrap">
          {tracks.map((name, index) => (
            <div className="submenu-item" key={index}>
              <div className="tracks">
                <ListOfTracks
                  items={name}
                  onClick={() => setCurrentTrack(tracks[index])}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="dropradioborder">
          <img src={vavborder} alt=""></img>
        </div>
      </div>
    </>
  );
};

export default Dropradio;
