import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import WsubGallery from "./Wsub-gallery";
import "./WSP-gallery.css";

const WSPGallery = ({ galleryImages }) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      {openModal && (
        <div className="sliderWrap">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            onClick={handleCloseModal}
          />

          <div className="fullScreenImage">
            <WsubGallery images={galleryImages[slideNumber].subgallery} />
          </div>
        </div>
      )}
      <div className="galleryWrap">
        {galleryImages.map((slide, index) => {
          return (
            <>
              <div
                className="tubnail"
                key={slide.id}
                onClick={() => handleOpenModal(index)}
              >
                <div className="single">
                  <img src={slide.img} alt="" />
                  <div className="tubtitle">{slide.title}</div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default WSPGallery;
