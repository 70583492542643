import React from "react";
import { useTranslation } from "react-i18next";
import { init } from "i18next";
import vavborder from "src/assets/images/border2.svg";
import boxtitle from "src/assets/images/box.svg";
import shaman from "src/assets/images/shaman.mp4";
import flayer from "src/assets/images/SUN11.jpg"
import Formas from "./forms";
import "./tickets.css";

const Tickets = ({ ticketsTitle }) => {
  const { t, ready} = useTranslation("global");
  if (!ready) return "loading translations...";
  const lineUp = t("lineUp", { returnObjects: init });
  const parttwo = t("ticketssecond.tempheader")
    .split("\n")
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  const tempheaderWithBr = t("tickets.tempheader")
  
    .split("\n")
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
    
     
  return (
    <div className="tickets_wrap">
      <div className="verhramka">
        <div className="ramka_img_wrap"></div>
        <img src={vavborder} alt=""></img>
        <div className="ramkatitle">
          <h2>{ticketsTitle}</h2>
          <img src={boxtitle} alt="" />
        </div>
      </div>
      <div className="tickets">
        <Formas />
      </div>
      <div className="tickets_content">
        <div className="flyer">
          <img src={flayer} alt="" />
        </div>
        <div className="skoro">{tempheaderWithBr}</div>
        <div className="line-up">
          {/* Display translated label */}
          {lineUp.map((artist) => (
            <div key={artist.artistName}>
              <a
                href={artist.soundCloudUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {artist.artistName}
              </a>
            </div>
          ))}
        </div>
        <div className="skoro">{parttwo}</div>
        <video controls={false} autoPlay loop>
          <source src={shaman} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="nizramka">
        <img src={vavborder} alt=""></img>
      </div>
    </div>
  );
};

export default Tickets;
